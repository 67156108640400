import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 1,
  class: "p-grid"
}
const _hoisted_3 = { class: "p-col-12" }
const _hoisted_4 = {
  key: 0,
  class: "c-menu-container"
}
const _hoisted_5 = {
  key: 1,
  class: "c-nutrition-empty-state-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_DaysPickerWithWeekDays = _resolveComponent("DaysPickerWithWeekDays")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_NutritionalPlanMenuCard = _resolveComponent("NutritionalPlanMenuCard")!
  const _component_MealFoodsCard = _resolveComponent("MealFoodsCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_DietDayConfirmModal = _resolveComponent("DietDayConfirmModal")!
  const _component_MenuCreateFormModal = _resolveComponent("MenuCreateFormModal")!
  const _component_AlternativeMealModal = _resolveComponent("AlternativeMealModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-nutrition"
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon p-button-secondary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDailyTracking()))
        }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("i", {
              class: "icon-external_link",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('nutrition.menu.button-go-follow-daily')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("main", _hoisted_1, [
      (_ctx.patient)
        ? (_openBlock(), _createBlock(_component_DaysPickerWithWeekDays, {
            key: 0,
            "day-selected": _ctx.selectedDateIndex,
            onOnDayPickerChanged: _ctx.onDayPickerChanged
          }, null, 8, ["day-selected", "onOnDayPickerChanged"]))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_LoadingComponent, { "spinner-color": "lavender-700" }, null, 512), [
        [_vShow, _ctx.loadingNutritionalPlans]
      ]),
      (!_ctx.loadingNutritionalPlans)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.showMenu && _ctx.diet && _ctx.dietDay?.nutritionalPlan)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_NutritionalPlanMenuCard, {
                      diet: _ctx.diet,
                      "day-selected-index": _ctx.selectedDateIndex,
                      mealsBySelectedDay: _ctx.mealsBySelectedDay,
                      onOnEditDietDay: _ctx.onEditDietDay
                    }, null, 8, ["diet", "day-selected-index", "mealsBySelectedDay", "onOnEditDietDay"]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mealsBySelectedDay, (meal) => {
                      return (_openBlock(), _createBlock(_component_MealFoodsCard, {
                        key: meal.id,
                        nutritionalPlan: _ctx.dietDay.nutritionalPlan,
                        mealMenu: meal,
                        userCanEdit: true,
                        "show-completed-meal-tag": false,
                        onOnUpdateMenuFood: _ctx.onMenuFoodUpdate,
                        onOnCreateAlternative: _ctx.onCreateAlternativeMeal
                      }, null, 8, ["nutritionalPlan", "mealMenu", "onOnUpdateMenuFood", "onOnCreateAlternative"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_EmptyState, {
                      heading: _ctx.$t('nutrition.menu.empty-state.title'),
                      text: _ctx.$t('nutrition.menu.empty-state.subtitle'),
                      secondary: false,
                      icon: "icon-file_blank_outline"
                    }, {
                      bottom: _withCtx(() => [
                        _createVNode(_component_Button, {
                          class: "p-button p-button-icon",
                          onClick: _ctx.onNewMenu
                        }, {
                          default: _withCtx(() => [
                            _cache[2] || (_cache[2] = _createElementVNode("i", {
                              class: "icon-plus",
                              "aria-hidden": "true"
                            }, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('nutrition.menu.new')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["heading", "text"])
                  ]))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_DietDayConfirmModal, {
      ref: "updateNutritionPlanModalRef",
      "day-selected-index": _ctx.selectedDateIndex,
      onUpdateMenu: _ctx.fetchDiet
    }, null, 8, ["day-selected-index", "onUpdateMenu"]),
    _createVNode(_component_MenuCreateFormModal, {
      ref: "menuCreateModalRef",
      "day-selected-index": _ctx.selectedDateIndex,
      onNewMenu: _ctx.fetchDiet
    }, null, 8, ["day-selected-index", "onNewMenu"]),
    _createVNode(_component_AlternativeMealModal, {
      ref: "alternativeMealModalRef",
      patientId: _ctx.patientId,
      onCreatedAlternativeMeal: _ctx.fetchDiet
    }, null, 8, ["patientId", "onCreatedAlternativeMeal"])
  ], 64))
}