
import { defineComponent, ref, computed, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { ServiceError } from '@/services/util/ServiceError';
import { Diet, DietDay, DietDayFormDto } from '@/models/NutritionalPlan';
import { useRoute } from 'vue-router';
import { isUpdateDietDayFormValid, updateDietDayForm } from '@/validation/nutritionalPlanForm';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';

export default defineComponent({
  emits: ['update-menu'],
  props: {
    daySelectedIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const route = useRoute();
    const showDialog = ref(false);
    const submitting = ref(false);
    const dietDay: Ref<DietDay | undefined> = ref();
    const dietDayFormDto: Ref<DietDayFormDto> = ref({} as DietDayFormDto);
    const validatedDietDayForm = computed(() => updateDietDayForm(dietDayFormDto.value));
    const valid = computed(() => isUpdateDietDayFormValid(validatedDietDayForm.value));
    const patientId = route.params.patientId as string;

    const openDialog = (dietDayFormDtoSelected: DietDayFormDto, diet: Diet) => {
      if (!dietDayFormDtoSelected || !diet) {
        return;
      }

      showDialog.value = true;
      dietDay.value = diet.dietDays.find((dietDay) => dietDay.day === props.daySelectedIndex + 1);
      dietDayFormDto.value = dietDayFormDtoSelected;
    };

    const onSubmit = async (e: Event) => {
      e.preventDefault();

      if (!valid.value) {
        return;
      }

      if (dietDayFormDto.value && dietDay.value) {
        try {
          const dietDayUpdated = await nutritionalPlanService.updateDietDay(
            patientId,
            dietDay.value.day,
            dietDayFormDto.value,
          );
          if (!(dietDayUpdated instanceof ServiceError)) {
            dietDay.value = dietDayUpdated.dietDays.find((dietDay) => dietDay.day === props.daySelectedIndex + 1);
            dietDayFormDto.value = new DietDayFormDto(
              dietDayUpdated.dietDays.find((dietDay) => dietDay.day === props.daySelectedIndex + 1)
                ?.nutritionalPlanId as string,
            );
            emit('update-menu');
          } else {
            toast.add({
              severity: 'error',
              summary: `${t('messages.notifications.errorEditMenu')} ${t('messages.notifications.tryLater')}`,
              life: 3000,
            });
          }
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditMenu')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } finally {
          showDialog.value = false;
        }
      }
    };

    return {
      submitting,
      showDialog,
      dietDayFormDto,
      openDialog,
      onSubmit,
    };
  },
});
