import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-container-picker" }
const _hoisted_2 = { class: "c-container-weekdays" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "c-filter__day" }

import moment from 'moment';
import i18n from '@/i18n';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DaysPickerWithWeekDays',
  props: {
  daySelected: {
    type: Number,
    required: true,
  },
},
  emits: ['onDayPickerChanged'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const weekDays = computed(() => {
  moment.locale(i18n.global.locale.value);
  const startDayIndex = moment().day(1).day();

  return Array.from({ length: 7 }, (_, i) => {
    const dayIndex = (startDayIndex + i) % 7;
    return {
      name: moment().day(dayIndex).format('dddd').toUpperCase(),
    };
  });
});

const onDayClick = (selectedIndexDay: number) => {
  emits('onDayPickerChanged', selectedIndexDay);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weekDays.value, (day, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["c-filter", {
          'c-filter--active': index == __props.daySelected,
          'c-filter--enabled': true,
        }]),
          onClick: ($event: any) => (onDayClick(index))
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(day.name), 1)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})